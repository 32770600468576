import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import dayjs from 'dayjs';

export default function ReportContainerTable({ report, endDate, startDate, shops, orders, container }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  // const calculateTotalDownload = ({ name }) => {
  //   return container
  //     .filter((e) => e.canceled === false)
  //     .reduce((total, report) => {
  //       return (
  //         total +
  //         report.item
  //           .filter((e) => e.name === name)
  //           .filter((el) => el.status === 'Хүлээж авсан')
  //           .filter((el) => el.switchsuccess === true)
  //           .reduce(
  //             (itemTotal, item) =>
  //               itemTotal +
  //               (
  //                 (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(endDate))
  //                   || (dayjs(item.date).isSame(endDate, 'date') && dayjs(item.date).isSame(startDate, 'date'))
  //                   ? item.staticUnit : 0),
  //             0
  //           )
  //       );
  //     }, 0);
  // };

  // const calculateTotalDownloadBalance = ({ name }) => {
  //   return container
  //     .filter((e) => e.canceled === false)
  //     .reduce((total, report) => {
  //       return (
  //         total +
  //         report.item
  //           .filter((e) => e.name === name)
  //           .filter((el) => el.status === 'Хүлээж авсан')
  //           .filter((el) => el.switchsuccess === true)
  //           .reduce(
  //             (itemTotal, item) =>
  //               itemTotal + item.staticUnit,
  //             // (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(dayjs(endDate)) ? item.unit : 0),
  //             0
  //           )
  //       );
  //     }, 0);
  // };

  // const calculateTotalOrdersBalance = ({ name }) => {
  //   return orders
  //     .reduce((total, report) => {
  //       return total + report.order
  //         .filter((e) => e.name === name)
  //         .reduce((itemTotal, item) => itemTotal + item.unit, 0);
  //     }, 0);
  // };

  const calculateTotalDelivered = ({ name }) => {
    return orders
      .filter((e) => e.status === 'Хүргэсэн' || e.status === 'Хэсэгчлэн хүргэсэн')
      .filter((e) => {
        return ((
          dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate)
          ||
          dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date')
        ) &&
          (
            dayjs(e.orderDate).subtract(8, 'hour').isAfter(endDate) ||
            dayjs(e.orderDate).subtract(8, 'hour').isSame(endDate, 'date')
          ))
      })
      .reduce((total, items) => {
        return total +
          items.order
            .filter((e) => {
              return e.name === name
            })
            .reduce((itemTotal, item) => itemTotal +
              item.unit, 0);
      }, 0);
  };

  // const calculateTotalCancleled = ({ name }) => {
  //   return orders
  //     .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
  //     .reduce((total, report) => {
  //       return total + report.order
  //         .filter((e) => e.name === name)
  //         .reduce((itemTotal, item) => itemTotal +
  //           (
  //             ((dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
  //               dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
  //             ) ?
  //               item.unit : 0), 0);
  //     }, 0);
  // };

  // const calculateTotalRemoved = ({ name }) => {
  //   return container
  //     .reduce((total, report) => {
  //       return total + report.item
  //         .filter((e) => {
  //           return e.name === name
  //         })
  //         .filter((el) => el.status === 'Зарлага')
  //         .filter((el) => el.switchsuccess === true)
  //         .reduce((itemTotal, item) => itemTotal +
  //           (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
  //             || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'dates')
  //             ? item.staticUnit : 0), 0);
  //     }, 0);
  // };

  // const calculateTotalOrders = ({ name }) => {
  //   return orders
  //     .reduce((total, report) => {
  //       return total + report.order
  //         .filter((e) => e.name === name)
  //         .reduce((itemTotal, item) => itemTotal +
  //           (
  //             (
  //               (dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate)
  //                 && dayjs(report.orderDate).subtract(8, 'hour').isAfter(endDate))
  //               || (dayjs(report.orderDate).subtract(8, 'hour').isSame(endDate, 'date')
  //                 && dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
  //             )
  //               ? item.unit : 0), 0);
  //     }, 0);
  // };

  // Filter

  // const calculateTotalOrders = ({ name }) => {
  //   return orders
  //     .reduce((total, report) => {
  //       return total + report.order
  //         .filter((e) => e.name === name)
  //         .reduce((itemTotal, item) => itemTotal + (
  //           ((dayjs(report.orderDate).isBefore(startDate)
  //             || dayjs(report.orderDate).isSame(startDate, 'date'))
  //             &&
  //             (dayjs(report.orderDate).isAfter(endDate)
  //               || dayjs(report.orderDate).isSame(endDate, 'date')))
  //             ? item.unit : 0
  //         ), 0);
  //     }, 0);
  // };

  const calculateTotalOrdersBalanceFilter = ({ name }) => {
    return orders
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal + (
            (
              (
                dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate.add(8, 'hours'))
                || dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate.add(8, 'hours'), 'date')
              ) &&
              (dayjs(report.orderDate).subtract(8, 'hour').isAfter(endDate)
                || dayjs(report.orderDate).subtract(8, 'hour').isSame(endDate, 'date')))
              ? item.unit : 0
          ), 0);
      }, 0);
  };

  const calculateTotalDownloadBalanceFilter = ({ name }) => {
    return container
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + (
                  (
                    (dayjs(item.date).isBefore(startDate) || dayjs(item.date).isSame(startDate, 'date'))
                    &&
                    (dayjs(item.date).isAfter(endDate) || dayjs(item.date).isSame(endDate, 'date'))
                  )
                    ? item.unit : 0),
              // (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(dayjs(endDate)) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  // const calculateTotalCancleledFilter = ({ name }) => {
  //   return orders
  //     .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
  //     .filter((e) => dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) ||
  //       dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
  //     .reduce((total, report) => {
  //       return total + report.order
  //         .filter((e) => e.name === name)
  //         .reduce((itemTotal, item) => itemTotal +
  //           (
  //             ((dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
  //               dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
  //             ) ?
  //               item.unit : 0), 0);
  //     }, 0);
  // };

  // const calculateTotalRemovedFilter = ({ name }) => {
  //   return container
  //     .reduce((total, report) => {
  //       return total + report.item
  //         .filter((e) => {
  //           return e.name === name
  //         })
  //         .filter((el) => el.status === 'Зарлага')
  //         .filter((el) => el.switchsuccess === true)
  //         // .filter((el) => dayjs(el.date))
  //         .filter((e) => dayjs(e.date).subtract(8, 'hour').isBefore(startDate) ||
  //           dayjs(e.date).subtract(8, 'hour').isSame(startDate, 'date'))
  //         .reduce((itemTotal, item) => itemTotal +
  //           (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
  //             || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'date')
  //             ? item.unit : 0), 0);
  //     }, 0);
  // };

  const calculateTotalOrders = ({ name }) => {
    return orders
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              (
                dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate) ||
                dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate, 'date')
              )
                ? item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalDownload = ({ name }) => {
    return container
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + (
                  (dayjs(item.date).isBefore(startDate) ||
                    dayjs(item.date).isSame(startDate, 'date')
                  ) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalCancleledFilter = ({ name }) => {
    return orders
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .filter((e) => dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) ||
        dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              ((dayjs(report.orderDate).isBefore(dayjs().add(8, 'hours'))
                // ||
                // dayjs(report.orderDate).add(8, 'hours').isSame(dayjs().add(8, 'hour'), 'date')
              )
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemovedFilter = ({ name }) => {
    return container
      .reduce((total, report) => {
        return total + report.item
          .filter((e) => {
            return e.name === name
          })
          .filter((el) => el.status === 'Зарлага')
          .filter((el) => el.switchsuccess === true)
          // .filter((el) => dayjs(el.date))
          .filter((e) => dayjs(e.date).subtract(8, 'hour').isBefore(startDate) ||
            dayjs(e.date).subtract(8, 'hour').isSame(startDate, 'date'))
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
              || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'date')
              ? item.unit : 0), 0);
      }, 0);
  };


  return (
    <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#2c9961', color: '#fff' }}>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Барааны нэр</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Татан авалт</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Хүргэгдсэн</TableCell>
            {/* <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Шүүлттэй үлдэгдэл</TableCell> */}
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үлдэгдэл</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт захиалга</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e, index) => (
              <TableRow className='hover'
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell component="th" scope="row">{e.title}</TableCell>
                <TableCell align="center">{
                  calculateTotalDownloadBalanceFilter({ name: e.title })
                }</TableCell>
                <TableCell align="center">{
                  calculateTotalDelivered({ name: e.title })
                }</TableCell>
                <TableCell align="center">{
                  (e.defaultbalance ? e.defaultbalance : 0) + (calculateTotalDownload({ name: e.title }) -
                    calculateTotalOrders({ name: e.title })
                    +
                    calculateTotalCancleledFilter({ name: e.title })
                    -
                    calculateTotalRemovedFilter({ name: e.title })
                  )
                }</TableCell>
                <TableCell align="center">{
                  calculateTotalOrdersBalanceFilter({ name: e.title })}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={report
          .length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}